<!-- 教务 -- 考勤管理 -- 行课设置 -- 列表 -->
<template>
  <list-template
      :loading="loading"
      :total="total"
      hasAdd
      @onAdd="$router.push('./add')"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onChangePage="changeCurrentPage"
      @onSearch="onSearch"
      @onHandle="tableHandle"
      @onReset="search = null"
      ref="table">
  </list-template>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import { getDayFromNow } from '@/utils'
import moment from "moment";

const dateArr = [getDayFromNow(-2), getDayFromNow(0)]
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(['page']),
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      loading: true,
      // 搜索框的选中数据
      search: null,
      // 数据总量
      total: 0,
      searchConfig: [
        {
          prop: 'school_id',
          placeholder: '请选择校区',
          tag: 'select',
          label: 'school_name',
          value: 'id',
          options: []
        },
        {
          prop: 'type',
          placeholder: '请选择类型',
          tag: 'select',
          label: 'label',
          value: 'value',
          options: []
        },
        {
          prop: 'status',
          placeholder: '请选择状态',
          tag: 'select',
          options: []
        },
        {
          prop: 'class_name',
          placeholder: '搜索课程名称',
        }
      ],
      tableData: [],
      tableConfig: [
        {width: "80rem", label: '序号', type: 'number'},
        {
          prop: 'class_name',
          label: '课程名称',
        }, {
          prop: 'type_preview',
          label: '行课类型',
        },
        {
          prop: 'date',
          label: '日期(星期)',
          render: (row) => {
            let num = moment(row.date).format('E');
            let str;
            switch (num) {
              case '1':
                str = '周一'; break
              case '2':
                str = '周二'; break
              case '3':
                str = '周三'; break
              case '4':
                str = '周四'; break
              case '5':
                str = '周五'; break
              case '6':
                str = '周六'; break
              case '7':
                str = '周日'; break
            }
            return row.date + '(' + str + ')'
          }
        },
        {
          prop: 'section',
          label: '上课时间',
          render: (row) => {
            return row.start_at + ' - ' + row.end_at
          }
        },
        {
          prop: 'creator_name',
          label: '申请人'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'status_cn',
          label: '状态  ',
          width: 110,
        },
        {
          label: '操作',
          handle: true,
          width: '100',
          buttons: (row) => row.options === "details" ? [{ type:"view", text:"详情" }] : [{ type:"edit", text:"审批" }],
        }
      ]
    }
  },
  activated() {
    this.getData()
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getStatus()
    this.getSchool()
    this.$_axios2.post('api/attendance/administrative-class-judge/type-options').then(res => {
      this.searchConfig[1].options = res.data.data
    })
  },
  methods: {
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get("api/attendance/administrative-class-judge/list", {
        params: {...search, page},
      }).then(res => {
        if (res.data.status === 0) {
          let data = res.data.data;
          this.tableData = data.list;
          this.total = data.page.total
        }
      }).finally(() => this.loading = false)

    },

    getStatus() {
      this.$_axios2('api/attendance/administrative-class-judge/status-option').then(res => {
        this.searchConfig[2].options = res.data.data
      })
    },

    getSchool() {
      this.$_axios2('api/attendance/administrative-class-judge/school').then(res => {
        this.searchConfig[0].options = res.data.data
      })
    },

    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row, handle) {
      const { type } = handle
      if(type === 'view') {
        this.$router.push('./details?id=' + row.id)
      } else if(type === 'approval') {
        this.$router.push('./details?id=' + row.id)
      }
    }
  }
}
</script>

<style scoped></style>
